@import 'mixins.scss';

@font-face {
  font-family: "SFProTextRegular";
  src: url('./newFonts/SFProTextRegular.woff') format('woff'),
       url('./newFonts/SFProTextRegular.woff2') format('woff2'),
       url('./newFonts/SFProTextRegular.ttf') format('truetype'),
       url('./newFonts/SFProTextRegular.otf') format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "SFProTextMedium";
  src: url('./newFonts/SFProTextMedium.woff') format('woff'),
       url('./newFonts/SFProTextMedium.woff2') format('woff2'),
       url('./newFonts/SFProTextMedium.ttf') format('truetype'),
       url('./newFonts/SFProTextMedium.otf') format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "SFProTextBold";
  src: url('./newFonts/SFProTextBold.woff') format('woff'),
       url('./newFonts/SFProTextBold.woff2') format('woff2'),
       url('./newFonts/SFProTextBold.ttf') format('truetype'),
       url('./newFonts/SFProTextBold.otf') format("opentype");
  font-weight: bold;
}

:root {
  --kit-color-primary: #1b55e3;
  --kit-font-family: 'SFProTextRegular', sans-serif;
}

html {
  font-size: 15px;
  @media (max-width: $sm-max-width) {
    font-size: 14px;
  }
}

body {
  font-family: $base-font-family;
}

// dark theme
[data-kit-theme='dark'] {
  body {
    background: darken($dark-gray-5, 3%);
  }
}
